import moment from "moment"

import { REPERTORIES } from "../../../../constants"
import {
  WorkBeneficiaryFrontDto,
  WorkFrontDto,
  WorkProducerFrontDto,
  WorkState,
} from "../../../../domain/api"
import { formatTEL, formatTextValue } from "../../../../helpers/formatText"
import { DetailList } from "./models"

export const getGeneralDetails = (
  work: WorkFrontDto,
  getCountry: (e: string) => string,
): DetailList[] => {
  const repertoryLabel =
    REPERTORIES.find((x) => x.value === work.repertory)?.label ?? ""

  const formatDuration = (duration: string | null | undefined): string => {
    if (!duration) return ""
    const durationMoment = moment(duration, "HH:mm:ss")
    return `${durationMoment.hours()}h ${durationMoment.minutes()}min ${durationMoment.seconds()}sec`
  }

  return [
    {
      title: "VOTRE OEUVRE",
      subfields: () => [
        {
          title: "Titre de l’œuvre",
          content: formatTextValue(work.title),
        },
        {
          title: "Titre secondaire",
          content: formatTextValue(work.translatedTitle),
        },
        {
          title: "Type d’œuvre",
          content: repertoryLabel,
        },
        {
          title: "Durée de l’œuvre",
          content: work?.duration ? formatDuration(work?.duration) : "-",
        },
        {
          title: "Titre de l’émission",
          content: formatTextValue(work.programTitle),
        },
        {
          title: "Exploitant",
          content: work.exploitation ?? "-",
        },
        {
          title: "Numéro ISAN",
          content: formatTextValue(work.isanCode),
        },
      ],
    },
    {
      title: "AUTEURS OU AUTRICES",
      field: "workBeneficiaries",
      subfields: (val) => {
        const values = val as WorkBeneficiaryFrontDto
        return [
          {
            title: `Auteur ${values.order} + 1}`,
            content:
              values.lastName || values.firstName
                ? `${values.lastName} ${values.firstName}`
                : "-",
            hideLabel: true,
          },
          {
            title: "Part de droits",
            content: `${
              values.partOfRight ? values.partOfRight.toPercentString() : "-"
            }`,
          },
          {
            title: "Téléphone",
            content: `${
              values.mobilePhone ? formatTEL(values.mobilePhone) : "-"
            }`,
          },
          {
            title: "Courriel",
            content: formatTextValue(values.email),
            isEmail: values.email !== "",
          },
        ]
      },
    },
    {
      title: "PRODUCTEURS",
      field: "workProducers",
      subfields: (val) => {
        const values = val as WorkProducerFrontDto
        return [
          {
            title: "Société de production",
            content: formatTextValue(values.society),
            hideLabel: true,
          },
          {
            condition: work.state === WorkState.DOL,
            title: "Pays de production",
            // @ts-ignore: Object is possibly 'null'
            content: getCountry(values.productionCountry),
          },
          {
            title: "Commentaires",
            content: formatTextValue(values.comment),
          },
        ]
      },
    },
  ]
}
